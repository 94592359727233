var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _vm.loading.find
        ? _c("a-skeleton", { attrs: { active: "" } })
        : [
            _c(
              "a-row",
              { attrs: { gutter: [16, 16] } },
              [
                _c(
                  "a-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "a-form-model",
                      {
                        ref: "form",
                        attrs: {
                          model: _vm.formData,
                          rules: _vm.formRules,
                          "wrapper-col": { span: 12 },
                          "label-col": { span: 9 },
                          "label-align": "left",
                          "data-testid": "trucking-sales-order-form"
                        }
                      },
                      [
                        _c(
                          "a-row",
                          { attrs: { gutter: [16, 16] } },
                          [
                            _c(
                              "a-col",
                              { attrs: { span: 12 } },
                              [
                                !!_vm.formData.documentNumber
                                  ? _c(
                                      "a-form-model-item",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "lbl_sales_order_number"
                                          ),
                                          prop: "documentNumber"
                                        }
                                      },
                                      [
                                        _c("a-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.formData.documentNumber,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "documentNumber",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formData.documentNumber"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                !!_vm.formData.documentReference
                                  ? _c(
                                      "a-form-model-item",
                                      {
                                        attrs: {
                                          label: _vm.$t("lbl_pi_number"),
                                          prop: "documentReference"
                                        }
                                      },
                                      [
                                        _c("a-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value:
                                              _vm.formData.documentReference,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "documentReference",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formData.documentReference"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "a-form-model-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("lbl_branch"),
                                      prop: "branch"
                                    }
                                  },
                                  [
                                    _c("SelectBranch", {
                                      attrs: {
                                        disabled:
                                          _vm.isCreatedFromTruckingApp ||
                                          _vm.isSubmitted,
                                        labelInValue: ""
                                      },
                                      model: {
                                        value: _vm.formData.branch,
                                        callback: function($$v) {
                                          _vm.$set(_vm.formData, "branch", $$v)
                                        },
                                        expression: "formData.branch"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "a-form-model-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("lbl_date"),
                                      prop: "date"
                                    }
                                  },
                                  [
                                    _c("a-date-picker", {
                                      staticClass: "w-100",
                                      attrs: {
                                        format:
                                          _vm.DATE_TIME_HOURS_DEFAULT_FORMAT,
                                        disabled:
                                          _vm.isCreatedFromTruckingApp ||
                                          _vm.isSubmitted,
                                        "show-time": ""
                                      },
                                      model: {
                                        value: _vm.formData.date,
                                        callback: function($$v) {
                                          _vm.$set(_vm.formData, "date", $$v)
                                        },
                                        expression: "formData.date"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "a-form-model-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("lbl_customer"),
                                      prop: "customer"
                                    }
                                  },
                                  [
                                    _c("SelectCustomer", {
                                      attrs: {
                                        disabled:
                                          _vm.isCreatedFromTruckingApp ||
                                          _vm.isSubmitted,
                                        labelInValue: ""
                                      },
                                      on: { change: _vm.onCustomerChange },
                                      model: {
                                        value: _vm.formData.customer,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "customer",
                                            $$v
                                          )
                                        },
                                        expression: "formData.customer"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "a-form-model-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("lbl_shipping_address"),
                                      prop: "shippingAddress"
                                    }
                                  },
                                  [
                                    _c("a-select", {
                                      attrs: {
                                        options: _vm.shipToOptions,
                                        "filter-option": false,
                                        "allow-clear": "",
                                        "show-search": "",
                                        "label-in-value": "",
                                        disabled:
                                          _vm.isCreatedFromTruckingApp ||
                                          _vm.isSubmitted
                                      },
                                      model: {
                                        value: _vm.formData.shippingAddress,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "shippingAddress",
                                            $$v
                                          )
                                        },
                                        expression: "formData.shippingAddress"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "a-form-model-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("lbl_billing_address"),
                                      prop: "billingAddress"
                                    }
                                  },
                                  [
                                    _c("a-select", {
                                      attrs: {
                                        options: _vm.billToOptions,
                                        "filter-option": false,
                                        "allow-clear": "",
                                        "show-search": "",
                                        "label-in-value": "",
                                        disabled:
                                          _vm.isCreatedFromTruckingApp ||
                                          _vm.isSubmitted
                                      },
                                      model: {
                                        value: _vm.formData.billingAddress,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "billingAddress",
                                            $$v
                                          )
                                        },
                                        expression: "formData.billingAddress"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "a-form-model-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("lbl_tax_calculation"),
                                      prop: "taxCalculation"
                                    }
                                  },
                                  [
                                    _c("SelectTaxCalculation", {
                                      attrs: {
                                        disabled:
                                          _vm.isCreatedFromTruckingApp ||
                                          _vm.isSubmitted,
                                        labelInValue: ""
                                      },
                                      on: {
                                        change: _vm.onTaxCalculationChange
                                      },
                                      model: {
                                        value: _vm.formData.taxCalculation,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "taxCalculation",
                                            $$v
                                          )
                                        },
                                        expression: "formData.taxCalculation"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "a-form-model-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("lbl_currency"),
                                      prop: "currency"
                                    }
                                  },
                                  [
                                    _c("SelectCurrency", {
                                      attrs: {
                                        disabled:
                                          _vm.isCreatedFromTruckingApp ||
                                          _vm.isSubmitted,
                                        labelInValue: ""
                                      },
                                      on: { change: _vm.onCurrencyChange },
                                      model: {
                                        value: _vm.formData.currency,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "currency",
                                            $$v
                                          )
                                        },
                                        expression: "formData.currency"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                !_vm.isIdr
                                  ? _c(
                                      "a-form-model-item",
                                      {
                                        attrs: {
                                          label: _vm.$t("lbl_currency_rate"),
                                          prop: "currencyRate"
                                        }
                                      },
                                      [
                                        _c("a-input-number", {
                                          staticClass: "w-100",
                                          attrs: {
                                            formatter: _vm.formatterNumber,
                                            parser: _vm.reverseFormatNumber,
                                            precision:
                                              _vm.storeBaseDecimalPlace,
                                            disabled:
                                              _vm.isCreatedFromTruckingApp ||
                                              _vm.isSubmitted
                                          },
                                          model: {
                                            value: _vm.formData.currencyRate,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "currencyRate",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression: "formData.currencyRate"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "a-form-model-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("lbl_term_of_payment"),
                                      prop: "termOfPayment"
                                    }
                                  },
                                  [
                                    _c("SelectTermOfPayment", {
                                      attrs: {
                                        disabled:
                                          _vm.isCreatedFromTruckingApp ||
                                          _vm.isSubmitted
                                      },
                                      model: {
                                        value: _vm.formData.termOfPayment,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "termOfPayment",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "formData.termOfPayment"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "a-form-model-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("lbl_notes"),
                                      prop: "notes"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "extra",
                                        fn: function() {
                                          return [
                                            _c("character-length", {
                                              attrs: {
                                                value: _vm.formData.notes
                                              }
                                            })
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  },
                                  [
                                    _c("a-textarea", {
                                      attrs: {
                                        disabled:
                                          _vm.isCreatedFromTruckingApp ||
                                          _vm.isSubmitted
                                      },
                                      model: {
                                        value: _vm.formData.notes,
                                        callback: function($$v) {
                                          _vm.$set(_vm.formData, "notes", $$v)
                                        },
                                        expression: "formData.notes"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm.formData.status
                                  ? _c(
                                      "a-form-model-item",
                                      {
                                        attrs: {
                                          label: _vm.$t("lbl_status"),
                                          prop: "status"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.formData.status) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: 24 } },
                  [
                    _c("a-table", {
                      attrs: {
                        columns: _vm.columns,
                        "data-source": _vm.formData.salesOrderLines,
                        pagination: {
                          showTotal: function(total) {
                            return _vm.$t("lbl_total_items", { total: total })
                          },
                          showSizeChanger: true
                        },
                        scroll: { x: "calc(1200px + 100%)" },
                        "row-selection": {
                          selectedRowKeys: _vm.selectedRowKeys,
                          onChange: _vm.onSelectedTableRowChange
                        },
                        size: "small",
                        "row-key": "rowId",
                        "row-class-name": function(_record, index) {
                          return index % 2 ? "bg-white" : "bg-gray-light"
                        },
                        "data-testid": "trucking-sales-order-table"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _c(
                                "a-space",
                                [
                                  _c(
                                    "a-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        disabled:
                                          _vm.isCreatedFromTruckingApp ||
                                          _vm.isSubmitted
                                      },
                                      on: { click: _vm.handleAddRow }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("lbl_add_row")) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-button",
                                    {
                                      attrs: {
                                        type: "danger",
                                        disabled:
                                          _vm.isCreatedFromTruckingApp ||
                                          _vm.isSubmitted
                                      },
                                      on: { click: _vm.handleDeleteRow }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("lbl_delete_row")) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "documentReference",
                          fn: function(text, row) {
                            return [
                              _c("a-input", {
                                staticClass: "w-100",
                                attrs: {
                                  disabled:
                                    _vm.isCreatedFromTruckingApp ||
                                    _vm.isSubmitted
                                },
                                model: {
                                  value: row.documentReference,
                                  callback: function($$v) {
                                    _vm.$set(row, "documentReference", $$v)
                                  },
                                  expression: "row.documentReference"
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "unitCode",
                          fn: function(text, row) {
                            return [
                              _c("a-select", {
                                staticClass: "w-100",
                                attrs: {
                                  options:
                                    row.truckOptions.length > 0
                                      ? row.truckOptions
                                      : _vm.truckOptions,
                                  loading:
                                    row.loadingTrucks || _vm.loading.getTrucks,
                                  disabled:
                                    _vm.isCreatedFromTruckingApp ||
                                    _vm.isSubmitted,
                                  "filter-option": false,
                                  "allow-clear": "",
                                  "show-search": "",
                                  "label-in-value": ""
                                },
                                on: {
                                  search: function($event) {
                                    return _vm.onSearchTruck(row, $event)
                                  },
                                  change: function($event) {
                                    return _vm.onAssetChange($event, row)
                                  }
                                },
                                model: {
                                  value: row.unitCode,
                                  callback: function($$v) {
                                    _vm.$set(row, "unitCode", $$v)
                                  },
                                  expression: "row.unitCode"
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "salesName",
                          fn: function(text, row) {
                            return [
                              _c("a-input", {
                                staticClass: "w-100",
                                attrs: {
                                  disabled:
                                    _vm.isCreatedFromTruckingApp ||
                                    _vm.isSubmitted
                                },
                                model: {
                                  value: row.salesName,
                                  callback: function($$v) {
                                    _vm.$set(row, "salesName", $$v)
                                  },
                                  expression: "row.salesName"
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "taxCode",
                          fn: function(text, row) {
                            return [
                              _c("a-select", {
                                staticClass: "w-100",
                                attrs: {
                                  options:
                                    row.taxCodeOptions.length > 0
                                      ? row.taxCodeOptions
                                      : _vm.taxCodeOptions,
                                  loading:
                                    row.loadingTaxCodes ||
                                    _vm.loading.getTaxCodes,
                                  disabled: _vm.isTaxNone || _vm.isSubmitted,
                                  "filter-option": false,
                                  "allow-clear": "",
                                  "show-search": "",
                                  "label-in-value": ""
                                },
                                on: {
                                  search: function($event) {
                                    return _vm.onSearchTaxCode(row, $event)
                                  },
                                  change: function($event) {
                                    return _vm.onTaxCodeChange($event, row)
                                  }
                                },
                                model: {
                                  value: row.taxCode,
                                  callback: function($$v) {
                                    _vm.$set(row, "taxCode", $$v)
                                  },
                                  expression: "row.taxCode"
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "price",
                          fn: function(text, row) {
                            return [
                              _c("a-input-number", {
                                staticClass: "w-100",
                                attrs: {
                                  formatter: _vm.formatterNumber,
                                  parser: _vm.reverseFormatNumber,
                                  precision: _vm.storeBaseDecimalPlace,
                                  min: 0,
                                  disabled:
                                    _vm.isCreatedFromTruckingApp ||
                                    _vm.isSubmitted
                                },
                                on: { change: _vm.calculateSalesOrderLines },
                                model: {
                                  value: row.price,
                                  callback: function($$v) {
                                    _vm.$set(row, "price", _vm._n($$v))
                                  },
                                  expression: "row.price"
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "qty",
                          fn: function(text, row) {
                            return [
                              _c("a-input-number", {
                                staticClass: "w-100",
                                attrs: {
                                  formatter: _vm.formatterNumber,
                                  parser: _vm.reverseFormatNumber,
                                  precision: _vm.storeBaseDecimalPlace,
                                  min: 0,
                                  disabled:
                                    _vm.isCreatedFromTruckingApp ||
                                    _vm.isSubmitted
                                },
                                on: { change: _vm.calculateSalesOrderLines },
                                model: {
                                  value: row.qty,
                                  callback: function($$v) {
                                    _vm.$set(row, "qty", _vm._n($$v))
                                  },
                                  expression: "row.qty"
                                }
                              })
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: 24 } },
                  [
                    _c("a-divider"),
                    _c("DisplayTotal", {
                      attrs: {
                        total: _vm.getTotalDpp,
                        totalDiscount: _vm.getTotalDiscount,
                        totalTax: _vm.getTotalTax,
                        grandTotal: _vm.getGrandTotal,
                        "data-testid": "trucking-sales-order-total"
                      }
                    }),
                    _c("a-divider")
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { span: 24, align: "end" } },
                  [
                    _c(
                      "a-space",
                      [
                        _c(
                          "a-button",
                          {
                            attrs: {
                              "data-testid": "trucking-sales-order-btn-back"
                            },
                            on: { click: _vm.handleBack }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")]
                        ),
                        _vm.isCreate &&
                        _vm.$can("create", "trucking-sales-order")
                          ? _c(
                              "a-button",
                              {
                                attrs: {
                                  loading: _vm.loading.draft,
                                  "data-testid":
                                    "trucking-sales-order-btn-draft"
                                },
                                on: { click: _vm.handleSaveDraft }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("lbl_save_as_draft")) +
                                    " "
                                )
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "a-popconfirm",
                          {
                            attrs: {
                              title: _vm.$t("pop_confirmation"),
                              "ok-text": _vm.$t("lbl_yes"),
                              "cancel-text": _vm.$t("lbl_no")
                            },
                            on: { confirm: _vm.handleCancel }
                          },
                          [
                            (_vm.isDraft || _vm.isSubmitted) &&
                            _vm.$can("update", "trucking-sales-order")
                              ? _c(
                                  "a-button",
                                  {
                                    attrs: {
                                      type: "danger",
                                      loading: _vm.loading.cancel,
                                      "data-testid":
                                        "trucking-sales-order-btn-cancel"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("lbl_cancel")) + " "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm.isDraft &&
                        _vm.$can("update", "trucking-sales-order")
                          ? _c(
                              "a-button",
                              {
                                attrs: {
                                  loading: _vm.loading.update,
                                  type: "primary",
                                  "data-testid":
                                    "trucking-sales-order-btn-update"
                                },
                                on: { click: _vm.handleUpdate }
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("lbl_update")) + " ")]
                            )
                          : _vm._e(),
                        (_vm.isCreate || _vm.isDraft) &&
                        (_vm.$can("update", "trucking-sales-order") ||
                          _vm.$can("create", "trucking-sales-order"))
                          ? _c(
                              "a-button",
                              {
                                attrs: {
                                  loading: _vm.loading.submit,
                                  type: "primary",
                                  "data-testid":
                                    "trucking-sales-order-btn-submit"
                                },
                                on: { click: _vm.handleSubmit }
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("lbl_submit")) + " ")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }